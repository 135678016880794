import React, { Fragment, useState, useEffect, useRef, useContext } from "react";
import { SessionContext } from '../context/sessionContext';

import DatePicker from "react-datepicker";
import { Form, Row, Col } from "react-bootstrap";

import styled from "styled-components";

import SelectInput from "./selectInput";
import {optionsTramitacionADA, optionsTramitacionResolucion, optionsTramitacionVisado, optionsTramitacionCPU} from "../datos/options";

const TextArea = styled.textarea`
  padding: 2px;
  //resize: none;
  overflow-y: hidden;
`;

const TramiteEditExp = ({ inputsTram, setInputsTram, inputs }) => {
	//quitar solicitud_adhesion_t
	const { original, cambioOriginal } = useContext(SessionContext);

	const {
		solicitud_adhesion_t, certif_urbanistico_municipal_t, certif_no_inundabilidad_t, anteproyecto_urbano_t, titulo_propiedad_t,
		informe_dominio_t, anotaciones_personales_t, admisibilidad_inicial_t, prefactibilidad_urbanistica_t, prefactibilidad_hidraulica_t,
		memoria_descriptiva_obras_t, energia_electrica_t, agua_potable_t, eliminacion_excretas_t, apertura_calles_t, acta_constitutiva_t, certif_vigencia_persona_juridica_t, plano_mensura_t, convenio_urbanistico_t, ordenanza_municipal_t, memoria_descriptiva_procedimientos_t,
		certif_tecnico_urbanistico_t, resolucion_afectacion_t, visado_plano_t,
		observaciones_internas_t,
		observaciones_reporte_t,
		fecha_envio_prefac_h_t, expediente_prefac_h_t, fecha_emision_prefac_h_t,
		fecha_visado_plano_t,
		fecha_envio_resol_t, fecha_emision_resol_t, numero_especial_resol_t,
		t_127_tramitacion_ada_prefac_h,
		t_131_observaciones_prefact_h,
		t_132_tramitacion_resol,
		t_136_observaciones_resol,
		t_137_tramitacion_visado,
		t_139_fecha_entrega, //139 - ADD 20-09-2022
    	t_140_observaciones_tramitacion, //140 - ADD 20-09-2022
		t_144_proyecto_hidraulico, //144 -- add 22-12-2022
		t_145_cpu_tramitacion, //145 --add 14-JUN-2024
		t_146_cpu_fecha_envio, //146 --add 14-JUN-2024
		t_147_cpu_fecha_emision, //147 --add 14-JUN-2024
		t_148_cpu_nro_gdeba, //148 --add 14-JUN-2024
		t_149_cpu_observaciones //149 --add 14-JUN-2024
	} = inputsTram;
	//console.log(t_127_tramitacion_ada_prefac_h);

	const textAreaRef = useRef(null);
	const textAreaObsInternas = useRef(null);
	const textAreaObsADA = useRef(null);
	const textAreaObsResolucion = useRef(null);
	const textAreaObsTramVisado = useRef(null);
	const textAreaObsCPU = useRef(null);


	//17-03-22** REcibo el valor de inputs (desde DATOS GENERALES) para mostrar checks habilitados o no, según condicion
	//const {area, tipo, sistema_agua, eliminacion_excretas, titular_tierra, plano_oficial} = inputs;
	const {tipo, sistema_agua, eliminacion_excretas, titular_tierra, area} = inputs;
	console.log(area);
	console.log("TIPO-->", tipo);

	//**14-03-2022: NO HABILITAR si se cumplen las tres condiciones: Área = 1, Sistema de Agua= 1 y Eliminación de Excretas= 1
	const [isDisabledPrefacHidraulica, setIsDisabledPrefacHidraulica] = useState(false);
	//console.log(isDisabledPrefacHidraulica);

	//**tipo. NO Habilitar si tipo = inmuebles del estado
	const [isDisabledTipo, setIsDisabledTipo] = useState(false);

	const [maxObservaciones, setMaxObservaciones] = useState(2000);
	useEffect(()=>{
		const maxOI = 2000;
		if(observaciones_internas_t){

			if(observaciones_internas_t !== "" || observaciones_internas_t !== null){
				var restantes = maxOI - observaciones_internas_t.length;
				setMaxObservaciones(restantes);
			}
		} else {
			setMaxObservaciones(maxOI);
		}
	}, [observaciones_internas_t]);	

	
	//**CONTADOR OBSERV Reportes

  //***PARA cuadro de texto
	const [val, setVal]=useState(observaciones_reporte_t);
  const [valInternas, setValInternas]=useState(observaciones_internas_t);
  const [valADA, setValADA]=useState(t_131_observaciones_prefact_h);
  const [valResolucion, setValResolucion]=useState(t_136_observaciones_resol);
  const [valTramVisado, setValTramVisado]=useState(t_140_observaciones_tramitacion);

	//const [val1, setVal1] = useState("svsd svsdsvsd svsdsvsd svsdsvsd svsd");
  //console.log("VAL1-------->", val1);

	const [maxObservR, setMaxObservR] = useState(3000);
	useEffect(()=>{
		const maxOR = 3000;
		if(observaciones_reporte_t){
			if(observaciones_reporte_t !== "" || observaciones_reporte_t !== null){
				var restantesR = maxOR - observaciones_reporte_t.length;
				setMaxObservR(restantesR);				
			}
		} else {
			setMaxObservR(maxOR);
		}
	}, [observaciones_reporte_t]);

  	//** contador Observac. tramitacion ADA
	const [maxObservTramADA, setMmaxObservTramADA] = useState(500);
	useEffect(()=>{
		const maxTADA = 500;
		if(t_131_observaciones_prefact_h){
			if(t_131_observaciones_prefact_h !== "" || t_131_observaciones_prefact_h !== null){
				var restantesR = maxTADA - t_131_observaciones_prefact_h.length;
				setMmaxObservTramADA(restantesR);
			}
		} else {
			setMmaxObservTramADA(maxTADA);
		}
	}, [t_131_observaciones_prefact_h]);
	//---cierra Observac. tramitacion ADA

	//** contador Observ. Resolucion
	const [maxObservResol, setMmaxObservResol] = useState(500);
	useEffect(()=>{
		const maxResol = 500;
		if(t_136_observaciones_resol){
			if(t_136_observaciones_resol !== "" || t_136_observaciones_resol !== null){
				var restantesR = maxResol - t_136_observaciones_resol.length;
				setMmaxObservResol(restantesR);
			}
		} else {
			setMmaxObservResol(maxResol);
		}
	}, [t_136_observaciones_resol]);

	//---cierra CONTADOR --------

	//**** contador Observaciones visado (140) maxObservaciones_140***
	const [maxObservaciones_140, setMaxObservaciones_140] = useState(2000);
	useEffect(()=>{
		const maxOV = 2000;
		if(t_140_observaciones_tramitacion){
			if(t_140_observaciones_tramitacion !== "" || t_140_observaciones_tramitacion !== null){
				var restantesRV = maxOV - t_140_observaciones_tramitacion.length;
				setMaxObservaciones_140(restantesRV);
			}
		} else {
			setMaxObservaciones_140(maxOV);
		}
	}, [t_140_observaciones_tramitacion]);
	//---cierra CONTADOR

	useEffect(() => {
		if (tipo === "INMUEBLES DEL ESTADO"){
			console.log("change tipo");
			setIsDisabledTipo(true);
		} else {
			setIsDisabledTipo(false);
		}
	},[tipo]);

	//Habilitar el check solo si "sistema_agua" = red
	const [isDisabledAgua, setIsDisabledAgua] = useState(true);

	useEffect(() => {
		if (sistema_agua === "RED"){ // Red
			setIsDisabledAgua(false);
		} else {
			setIsDisabledAgua(true);
			setInputsTram({ ...inputsTram, agua_potable_t: eval(false) });
		}
	}, [sistema_agua]);

	//Habilitar el check solo si "eliminacion de excertas" = red_cloacal
	const [isDisabledCloaca, setIsDisabledCloaca] = useState(true);

	useEffect(() => {
		if (eliminacion_excretas === "RED"){ // Red //1
			setIsDisabledCloaca(false); //Habilitado
		} else {
			setIsDisabledCloaca(true); //Deshabilitado
			setInputsTram({ ...inputsTram, eliminacion_excretas_t: eval(false) }); // SI está deshabilitado, deberia estar limpio tambien
		}
	}, [eliminacion_excretas]);

	//****04-07-2023 */
	//Deshabilitar el check de 'Proyecto Hidraulico'(Tramitac) solo si "Area" (Datos Generales)=Urbana
	const [isDisabledProy_Hidraul, setIsDisabledProy_Hidraul] = useState(false);

	useEffect(()=>{
		if(area === "URBANA"){
			console.log("DESHABILITAR Proy Hidr");
			setIsDisabledProy_Hidraul(true);
		} else {
			console.log("HABILITAR Proy Hidr");
			setIsDisabledProy_Hidraul(false);
		}
	}, [area])

	//-------04-07-2023 --/

	//Cuando cambia Titular de la tierra, afecta a dos checks
	//---> NO HABILITAR si es ----> municipio, provincia (ibva u otros), estado nacional, banco hipotecario, otros, sin dato
	//** habilitar solo cuando sea 4, 5, 6, 7, 8, 9 o 10
	const [isDisabledActa, setIsDisabledActa] = useState(true);

	useEffect(() => {
		//if(titular_tierra=== "" || titular_tierra === "municipio" || titular_tierra === "provincia (ibva u otros)" || titular_tierra === "estado nacional" || titular_tierra === "banco hipotecario" || titular_tierra === "otros" || titular_tierra === "sin dato"){
		if(titular_tierra=== "" || titular_tierra === "MUNICIPIO" || titular_tierra === "PROVINCIA (IVBA u otros)" || titular_tierra === "ESTADO NACIONAL" ||titular_tierra==="PRIVADOS PARTICULARES"||titular_tierra==="CONDOMINIO"||titular_tierra === "BANCO HIPOTECARIO" || titular_tierra === "OTROS" || titular_tierra === "SIN DATO"){
			setIsDisabledActa(true)
			setInputsTram({
				...inputsTram,
				acta_constitutiva_t: eval(false),
				certif_vigencia_persona_juridica_t: eval(false)
			 })
		} else {
			setIsDisabledActa(false)
		}

	}, [titular_tierra]);

	//**estado para Convenio Urbanistico ---> HABILITAR solo cuando Tipo === 2, 3
	const [isDisabledConvenioU, setIsDisabledConvenioU] = useState(true); //está DISABLED por deault (0) y 1
	const [isDisabledAnotaciones, setIsDisabledAnotaciones] = useState(false); //inicialmente ESTÁ Habilitado

	useEffect(() => {
		//if( tipo === "procesos de organizacion colectiva" || tipo === "convenio urbanistico"){ // 2, 3
		if( tipo === "PROCESOS DE ORGANIZACION COLECTIVA" || tipo === "CONVENIO URBANÍSTICO"){ // 2, 3
			setIsDisabledConvenioU(false); //Disabled false ---> Activo
			setIsDisabledAnotaciones(false);//Disabled false ---> Activo
		} else if(tipo === "INMUEBLES DEL ESTADO") { //1 //inmuebles del estado
			setIsDisabledConvenioU(true)
			setIsDisabledAnotaciones(true);//Deshabilitado
			setInputsTram({
				...inputsTram,
				convenio_urbanistico_t: eval(false),
				anotaciones_personales_t: eval(false)
			});
		} else if (tipo === ""){ //0
			setIsDisabledConvenioU(true);
			setIsDisabledAnotaciones(false);//Habilitado
			setInputsTram({
				...inputsTram,
				convenio_urbanistico_t: eval(false),
				anotaciones_personales_t: eval(false)
			});
		}
	}, [tipo]);

	//**estado Visado de Plano
	const [isDisabledVisadoPlano, setIsDisabledVisadoPlano] = useState(false);

	const onChange = (e, date) => {
		//console.log(e.target.type);
		//setOriginal(1);
		cambioOriginal(1);

		if (e.target.type === 'textarea' || e.target.type === 'text' || e.target.type === 'select-one') {
			setInputsTram({ ...inputsTram, [e.target.name]: e.target.value });

			if (e.target.name === 'observaciones_reporte_t') {
				setVal(e.target.value);
			}

			if (e.target.name === 'observaciones_internas_t') {
				setValInternas(e.target.value);
			}

			if (e.target.name === 't_131_observaciones_prefact_h') {
				setValADA(e.target.value);
			}

			if (e.target.name === 't_136_observaciones_resol') {
				setValResolucion(e.target.value);
			}
			//t_140_observaciones_tramitacion
			if (e.target.name === 't_140_observaciones_tramitacion') {
				setValTramVisado(e.target.value);
			}

			e.target.style.height = 'auto';
			e.target.style.height = `${e.target.scrollHeight}px`;

		} else if (e.target.type === 'checkbox') {
			setInputsTram({ ...inputsTram, [e.target.name]: e.target.checked });
		} else {
			//console.log("CAMBIO UN DATEPICKER");
		}
	}

	const handleFecha1 = (item) => {
		//setOriginal(1);
		cambioOriginal(1);
		const fecha = item.value;
		//console.log("cambio DATE Fecha 1");
		setInputsTram({ ...inputsTram, [item.name]: fecha });
	}

	return (
		<Fragment>
		<div>
			{/*<h6 className="mt-5 text-center">TRAMITACIÓN DEL EXPEDIENTE</h6>*/}
			<div className="container_row">{/* A --Dentro dos columnas****************************************/}
			<div className="container_div_column">{/*columna 1*/}
				<Row>
					<Col>
						<h6 className="mt-4 text-left subtitulos">ETAPA 1</h6>

						<Form.Check type="checkbox">
							<Form.Check.Input name="solicitud_adhesion_t" checked={solicitud_adhesion_t} type="checkbox" onChange={e => onChange(e)}/>
							<Form.Check.Label>{`Nota de solicitud de adhesión al programa`}</Form.Check.Label>
	      		</Form.Check>

						<Form.Check type="checkbox">
	        		<Form.Check.Input name="certif_urbanistico_municipal_t" checked={certif_urbanistico_municipal_t} type="checkbox" onChange={e => onChange(e)}/>
	        		<Form.Check.Label>{`Memoria descriptiva del proyecto y entorno urbano`}</Form.Check.Label>
	      		</Form.Check>

						<Form.Check type="checkbox">
	        		<Form.Check.Input name="certif_no_inundabilidad_t" checked={certif_no_inundabilidad_t} type="checkbox" onChange={e => onChange(e)}/>
	        		<Form.Check.Label>{`Certificado de no inundabilidad del predio`}</Form.Check.Label>
	      		</Form.Check>
						<Form.Check type="checkbox">
	        		<Form.Check.Input name="anteproyecto_urbano_t" checked={anteproyecto_urbano_t} type="checkbox" onChange={e => onChange(e)}/>
	        		<Form.Check.Label>{`Anteproyecto urbano`}</Form.Check.Label>
	      		</Form.Check>
						<Form.Check type="checkbox">
	        		<Form.Check.Input name="titulo_propiedad_t" checked={titulo_propiedad_t} type="checkbox" onChange={e => onChange(e)}/>
	        		<Form.Check.Label>{`Título de propiedad y/o datos de inscripción dominial`}</Form.Check.Label>
	      		</Form.Check>
					</Col>
					<Col>
						<h6 className="mt-4 text-left subtitulos">ETAPA 2</h6>

						<Form.Check type="checkbox">
							<Form.Check.Input name="memoria_descriptiva_obras_t" checked={memoria_descriptiva_obras_t} type="checkbox" onChange={e => onChange(e)}/>
							<Form.Check.Label>{`Memoria descriptiva de las obras a ejecutar `}</Form.Check.Label>
						</Form.Check>

						<Form.Check type="checkbox">
							<Form.Check.Input name="energia_electrica_t" checked={energia_electrica_t} type="checkbox" onChange={e => onChange(e)}/>
							<Form.Check.Label>{`Prefactibilidad de energía eléctrica`}</Form.Check.Label>
						</Form.Check>

						<Form.Check type="checkbox">
							<Form.Check.Input name="agua_potable_t" checked={agua_potable_t} type="checkbox" onChange={e => onChange(e)} disabled={isDisabledAgua}/>
							<Form.Check.Label>{`Prefactibilidad de agua potable por red`}</Form.Check.Label>
						</Form.Check>
						<Form.Check type="checkbox">
							<Form.Check.Input name="eliminacion_excretas_t" checked={eliminacion_excretas_t} type="checkbox" onChange={e => onChange(e)} disabled={isDisabledCloaca}/>
							<Form.Check.Label>{`Prefactibilidad de red cloacal`}</Form.Check.Label>
						</Form.Check>
						<Form.Check type="checkbox">
							<Form.Check.Input name="apertura_calles_t" checked={apertura_calles_t} type="checkbox" onChange={e => onChange(e)}/>
							<Form.Check.Label>{`Factibilidad apertura de calles`}</Form.Check.Label>
						</Form.Check>

						<Form.Check type="checkbox">
							<Form.Check.Input name="acta_constitutiva_t" checked={acta_constitutiva_t} type="checkbox" onChange={e => onChange(e)} disabled={isDisabledActa}/>
							<Form.Check.Label>{`Acta constitutiva persona jurídica`}</Form.Check.Label>
						</Form.Check>
						<Form.Check type="checkbox">
							<Form.Check.Input name="certif_vigencia_persona_juridica_t" checked={certif_vigencia_persona_juridica_t} type="checkbox" onChange={e => onChange(e)} disabled={isDisabledActa}/>
							<Form.Check.Label>{`Certificado de vigencia de persona jurídica`}</Form.Check.Label>
						</Form.Check>

						<Form.Check type="checkbox">
							<Form.Check.Input name="plano_mensura_t" checked={plano_mensura_t} type="checkbox" onChange={e => onChange(e)}/>
							<Form.Check.Label>{`Plano de mensura y división`}</Form.Check.Label>
						</Form.Check>
						<Form.Check type="checkbox">
							<Form.Check.Input name="convenio_urbanistico_t" checked={convenio_urbanistico_t} type="checkbox" onChange={e => onChange(e)} disabled={isDisabledConvenioU} />
							<Form.Check.Label>{`Convenio urbanístico`}</Form.Check.Label>
						</Form.Check>
						<Form.Check type="checkbox">
							<Form.Check.Input name="ordenanza_municipal_t" checked={ordenanza_municipal_t} type="checkbox" onChange={e => onChange(e)}/>
							<Form.Check.Label>{`Ordenanza municipal`}</Form.Check.Label>
						</Form.Check>
						<Form.Check type="checkbox">
							<Form.Check.Input name="memoria_descriptiva_procedimientos_t" checked={memoria_descriptiva_procedimientos_t} type="checkbox" onChange={e => onChange(e)}/>
							<Form.Check.Label>{`Memoria descriptiva del procedimiento de adjudicación`}</Form.Check.Label>
						</Form.Check>
						<Form.Check type="checkbox">
							<Form.Check.Input name="t_144_proyecto_hidraulico" checked={t_144_proyecto_hidraulico} disabled={isDisabledProy_Hidraul} type="checkbox" onChange={e => onChange(e)}/>
							<Form.Check.Label>{`Proyecto Hidráulico`}</Form.Check.Label>
						</Form.Check>
					</Col>


				</Row>
			</div>{/*cierra container_div_column*/}
			{/*cierra div mb-3 */}


			<div className="container_div_column">{/* mb-3 */}
				<Row>
					<Col>
						<h6 className="mt-4 text-left subtitulos">TRAMITACIONES DE LA DPPH</h6>

						<Form.Check type="checkbox">
							<Form.Check.Input name="informe_dominio_t" checked={informe_dominio_t} type="checkbox" onChange={e => onChange(e)} />
							<Form.Check.Label>{`Informe de dominio`}</Form.Check.Label>
						</Form.Check>
						<Form.Check type="checkbox">
							<Form.Check.Input name="anotaciones_personales_t" checked={anotaciones_personales_t} type="checkbox" onChange={e => onChange(e)} disabled={isDisabledTipo} />
							<Form.Check.Label>{`Anotaciones personales`}</Form.Check.Label>
						</Form.Check>
						<Form.Check type="checkbox">
							<Form.Check.Input name="admisibilidad_inicial_t" checked={admisibilidad_inicial_t} type="checkbox" onChange={e => onChange(e)} />
							<Form.Check.Label>{`Admisibilidad inicial`}</Form.Check.Label>
						</Form.Check>
						<Form.Check type="checkbox">
							<Form.Check.Input name="prefactibilidad_urbanistica_t" checked={prefactibilidad_urbanistica_t} type="checkbox" onChange={e => onChange(e)} />
							<Form.Check.Label>{`Prefactibilidad urbanística `}</Form.Check.Label>
						</Form.Check>
						<Form.Check type="checkbox">
							<Form.Check.Input name="prefactibilidad_hidraulica_t" checked={prefactibilidad_hidraulica_t} type="checkbox" onChange={e => onChange(e)} disabled={isDisabledPrefacHidraulica} />
							<Form.Check.Label>{`Prefactibilidad hidráulica y permisos - ADA`}</Form.Check.Label>
						</Form.Check>
					</Col>


					<Col>
						<h6 className="mt-5 text-left"></h6>
						<Form.Check type="checkbox">
							<Form.Check.Input name="certif_tecnico_urbanistico_t" checked={certif_tecnico_urbanistico_t} type="checkbox" onChange={e => onChange(e)} />
							<Form.Check.Label>{`Certificado técnico urbanístico`}</Form.Check.Label>
						</Form.Check>
						<Form.Check type="checkbox">
							<Form.Check.Input name="resolucion_afectacion_t" checked={resolucion_afectacion_t} type="checkbox" onChange={e => onChange(e)} />
							<Form.Check.Label>{`Resolución de afectación`}</Form.Check.Label>
						</Form.Check>
						<Form.Check type="checkbox">
							<Form.Check.Input name="visado_plano_t" checked={visado_plano_t} type="checkbox" onChange={e => onChange(e)} disabled={isDisabledVisadoPlano} />
							<Form.Check.Label>{`Visado de plano`}</Form.Check.Label>
						</Form.Check>
					</Col>
				</Row>
			</div>{/* cierra column*/}
			</div>{/*cierra container_row A*/}

			<div className="container_row">{/* B --Dentro dos columnas***********************************************/}

				<div className="container_div_column">{/*mb-3*/}
					{/*DOS*/}
					<div className="input-container">
						{/* 126 */}
						<textarea
							title="Observaciones para reporte al municipio"
							type="text"
							id="pr2"
							ref={textAreaRef}
							maxLength="3000"
							value={observaciones_reporte_t}
							name="observaciones_reporte_t"
							onChange={e => onChange(e)}
							rows={5}
						/>
						<label alt="Observaciones para reporte al municipio" for="pr2">Observaciones para reporte al municipio</label>
						<p align="right" style={{ fontSize: "0.9em" }}>Caracteres restantes: {maxObservR}</p>
					</div>
				</div>{/* cierra container_div_column*/}

				<div className="container_div_column">{/*mb-3*/}
					{/*UNO*/}
					<div className="input-container">
						{/* 125 */}
						<textarea
							title="Observaciones internas"
							type="text"
							id="id_observaciones_internas_t"
							ref={textAreaObsInternas}
							maxLength="2000"
							value={observaciones_internas_t}
							name="observaciones_internas_t"
							onChange={e => onChange(e)}
							rows={5}
						/>
						<label alt="Observaciones internas" for="id_observaciones_internas_t">Observaciones Internas</label>
						<p align="right" style={{ fontSize: "0.9em" }}>Caracteres restantes: {maxObservaciones}</p>
					</div>
				</div>{/* cierra column*/}

			</div>{/*cierra container_left B*/}


			<div className="container_row">{/* C -- Dentro dos columnas**********************************************************/}
				<div className="container_div_column">{/*mb-3*/}
					{/*TRES*/}
					{/*ABRE bloque PREFACTIBILIDAD HIDRÁULICA*/}
					{/*<div style={{border:"4px solid blue"}}>*/}
					<h6 className="mt-4 text-left subtitulos">PREFACTIBILIDAD HIDRÁULICA</h6>

					<div className="container_left">
						<div className="input-container">
							{/*<span className="span_selectEdit">{t_127_tramitacion_ada_prefac_h ? "Tramitación ADA": ""}</span>*/}
							<SelectInput
								name="t_127_tramitacion_ada_prefac_h"
								id="id_t_127_tramitacion_ada_prefac_h"
								defaultValue = {t_127_tramitacion_ada_prefac_h}
								onChange={e => onChange(e)}
								options={optionsTramitacionADA}
							/>
							<label alt="Tramitación ADA" for="id_t_127_tramitacion_ada_prefac_h">Tramitación ADA</label>
						</div>{/*cierra ---> input-container */}
					</div>{/*cierra ---> container_left */}

					<div className="container_left">
						<div className="input-container">
							<DatePicker
								title="Fecha de envío"
								name="fecha_envio_prefac_h_t"
								id="id_fecha_envio_prefac_h_t"
								dateFormat="dd/MM/yyyy"
								className="form-control"
								selected={fecha_envio_prefac_h_t}
								onChange={ (date) => handleFecha1({ name: "fecha_envio_prefac_h_t", value: date })}
							/>
							<label className="label-fecha" alt="Fecha de envío" for="id_fecha_envio_prefac_h_t">Fecha de envío</label>
						</div>{/*cierra ---> input-container */}
					</div>{/*cierra ---> container_left */}

					<div className="container_left">
						<div className="input-container">
							<input
								title="Expediente ADA"
								type="text"
								id="id_expediente_prefac_h_t"
								maxLength="35"
								value = {expediente_prefac_h_t}
								name="expediente_prefac_h_t"
								onChange={e => onChange(e)}
							/>
							<label alt="Expediente ADA" for="id_expediente_prefac_h_t">Expediente ADA</label> {/* 128 */}
						</div>{/*cierra ---> input-container */}
					</div>{/*cierra ---> container_left */}

					<div className="container_left">
						<div className="input-container">
							<DatePicker
								title="Fecha de emisión"
								name="fecha_emision_prefac_h_t"
								id="id_fecha_emision_prefac_h_t"
								dateFormat="dd/MM/yyyy"
								className="form-control"
								selected={fecha_emision_prefac_h_t}
								onChange={ (date) => handleFecha1({ name: "fecha_emision_prefac_h_t", value: date })}
							/>
							<label alt="Fecha de emisión" for="id_fecha_emision_prefac_h_t">Fecha de emisión</label>
						</div>{/*cierra ---> input-container */}
					</div>{/*cierra ---> container_left */}

					<div className="container_left" style={{ height: "auto"}}>
						<div className="input-container">
							<textarea
								title="Observaciones tramitación ADA"
								type="text"
								id="id_observaciones_prefact_h"
                ref={textAreaObsADA}
								maxLength="500"
								name="t_131_observaciones_prefact_h"
								value={t_131_observaciones_prefact_h}
								onChange={e => onChange(e)}
                rows={5}
							/>
							<label alt="Observaciones tramitación ADA" for="id_observaciones_prefact_h">Observaciones tramitación ADA </label>
							<p align="right" style={{ fontSize: "0.9em"}}>Caracteres restantes: {maxObservTramADA}</p>
						</div>{/*cierra ---> input-container */}
					</div>{/*cierra ---> container_left */}
					{/*</div> cierra bloque PREFACTIBILIDAD HIDRÁULICA*/}
				</div>{/* cierra container_div_column*/}

				<div className="container_div_column">{/*ABRE Nueva columna **************************************************/}
					{/*CUATRO*/}
					<h6 className="mt-4 text-left subtitulos">CERTIFICADO DE PREFACTIBILIDAD URBANÍSTICA</h6>

					<div className="container_left">
						<div className="input-container">
							<SelectInput
								name="t_145_cpu_tramitacion"
								id="id_t_145_cpu_tramitacion"
								defaultValue={t_145_cpu_tramitacion}
								onChange={e => onChange(e)}
								options={optionsTramitacionCPU}
							/>
							<label alt="Tramitación ADA" for="id_t_145_cpu_tramitacion">Tramitación CPU</label>
						</div>
					</div>

					<div className="container_left">
						<div className="input-container">
							<DatePicker
								title="Fecha de envío"
								name="t_146_cpu_fecha_envio"
								id="id_t_146_cpu_fecha_envio"
								dateFormat="dd/MM/yyyy"
								className="form-control"
								selected={t_146_cpu_fecha_envio}
								onChange={(date) => handleFecha1({ name: "t_146_cpu_fecha_envio", value: date })}
							/>
							<label className="label-fecha" alt="Fecha de envío" for="id_t_146_cpu_fecha_envio">Fecha de envío</label>
						</div>
					</div>

					<div className="container_left">
						<div className="input-container">
							<DatePicker
								title="Fecha de emisión"
								name="t_147_cpu_fecha_emision"
								id="id_t_147_cpu_fecha_emision"
								dateFormat="dd/MM/yyyy"
								className="form-control"
								selected={t_147_cpu_fecha_emision}
								onChange={(date) => handleFecha1({ name: "t_147_cpu_fecha_emision", value: date })}
							/>
							<label alt="Fecha de emisión" for="id_t_147_cpu_fecha_emision">Fecha de emisión</label>
						</div>
					</div>

					<div className="container_left">
						<div className="input-container">
							<input
								title="Nro. GDEBA"
								name="t_148_cpu_nro_gdeba"								
								id="id_t_148_cpu_nro_gdeba"
								type="text"
								maxLength="35"
								value={t_148_cpu_nro_gdeba}								
								onChange={e => onChange(e)}
							/>
							<label alt="Nro. GDEBA" for="id_t_148_cpu_nro_gdeba">Nro. GDEBA</label> {/* 128 */}
						</div>
					</div>					

					<div className="container_left" style={{ height: "auto" }}>
						<div className="input-container">
							<textarea
								title="Observaciones tramitación CPU"
								name="t_149_cpu_observaciones"
								id="id_t_149_cpu_observaciones"
								type="text"
								ref={textAreaObsCPU}
								maxLength="500"								
								value={t_149_cpu_observaciones}
								onChange={e => onChange(e)}
								rows={5}
							/>
							<label alt="Observaciones tramitación CPU" for="id_t_149_cpu_observaciones">Observaciones tramitación CPU </label>
							<p align="right" style={{ fontSize: "0.9em" }}>Caracteres restantes: {maxObservTramADA}</p>
						</div>
					</div>					

					{/*</div>---cierra  NUEVA CPU--*/}
				</div>{/* cierra container_div_column*/}

			</div>{/*cierra container_left (tiene 2 columnas dentro) - C */}

			<div className="container_row">{/* D -- Dentro dos columnas********************************************************/}
				<div className="container_div_column">{/*mb-3*/}
					{/*CINCO*/}
					<h6 className="mt-4 text-left subtitulos">VISADO DE PLANO - HÁBITAT</h6>

					<div className="container_left" >
						<div className="input-container">
							<SelectInput
								name="t_137_tramitacion_visado"
								id="id_t_137_tramitacion_visado"
								defaultValue = {t_137_tramitacion_visado}
								onChange={e => onChange(e)}
								options={optionsTramitacionVisado}
								//className="form-control my-3"
							/>
							<label alt="Tramitación Visado" for="id_t_137_tramitacion_visado">Tramitación visado</label>
						</div>
					</div>{/*cierra ---> container_left */}

					<div className="container_left">
						<div className="input-container">
							<DatePicker
					      placeholder="Fecha de visado" title="Fecha de visado"
					      name="fecha_visado_plano_t"
								id="id_fecha_visado_plano_t"
					      dateFormat="dd/MM/yyyy"
					      className="form-control"
					      selected={fecha_visado_plano_t}
					      //onChange={handleFecha3}
								onChange={ (date) => handleFecha1({ name: "fecha_visado_plano_t", value: date })}
								//placeholderText="Fecha de visado"
				      />
							<label alt="Fecha de visado" for="id_fecha_visado_plano_t">Fecha de visado</label>
						</div>
					</div>{/*cierra ---> container_left */}

					<div className="container_left">
						<div className="input-container">
							<DatePicker
								placeholder="Fecha de entrega" title="Fecha de entrega"
								name="t_139_fecha_entrega"
								id="id_t_139_fecha_entrega"
								dateFormat="dd/MM/yyyy"
								className="form-control"
								selected={t_139_fecha_entrega}
								onChange={ (date) => handleFecha1({ name: "t_139_fecha_entrega", value: date })}
							/>
							<label alt="Fecha de entrega" for="id_t_139_fecha_entrega">Fecha de entrega</label>
						</div>
					</div>{/*cierra ---> container_left */}

					<div className="container_left" style={{ height: "auto"}}>
						<div className="input-container">
							<textarea
								title="Observaciones tramitación visado"
								type="text"
								id="id_t_140_observaciones_tramitacion"
                ref={textAreaObsTramVisado}
								maxLength="2000"
								value={t_140_observaciones_tramitacion}
								name="t_140_observaciones_tramitacion"
								onChange={e => onChange(e)}
                rows={5}
							/>
							<label alt="Observaciones tramitación visado" for="id_t_140_observaciones_tramitacion">Observaciones tramitación visado</label>
							<p align="right" style={{ fontSize: "0.9em"}}>Caracteres restantes: {maxObservaciones_140}</p>
						</div>
					</div>{/* cierra ---> container_left */}

          {/* NO USAR - solo pruebas
					<div>
		      	<TextArea ref={textAreaRef1} value={val1} onChange={e => onChange1(e)} rows={5} />
		    	</div>

          <textarea
            ref={ textAreaRef2 }
            value={ value }
            onChange={ (evt) => handleChangeValue(evt.target.value) }
            style={{ height: height ? `${ height }px` : 'auto' }}
          />
          */}

				{/*</div>---cierra  VISADO DE PLANO--*/}
				</div>{/* cierra container_div_column*/}

				<div className="container_div_column">{/*mb-3*/}
					{/*SEIS*/}
					<h6 className="mt-4 text-left subtitulos">RESOLUCIÓN</h6>

					<div className="container_left">
						<div className="input-container">
							{/*<span className="span_selectEdit">{t_132_tramitacion_resol ? "Tramitación Resolución": ""}</span>*/}
							<SelectInput
								name="t_132_tramitacion_resol"
								id="id_t_132_tramitacion_resol"
								defaultValue={t_132_tramitacion_resol}
								onChange={e => onChange(e)}
								options={optionsTramitacionResolucion}
							/>
							<label alt="Tramitación Resolución" for="id_t_132_tramitacion_resol">Tramitación resolución</label>
						</div>{/*cierra ---> input-container */}
					</div>{/*cierra ---> container_left */}

					<div className="container_left">
						<div className="input-container">
							<DatePicker
								//id="startDate"
								title="Fecha de envío"
								id="id_fecha_envio_resol_t"
								name="fecha_envio_resol_t"
								dateFormat="dd/MM/yyyy"
								className="form-control"
								selected={fecha_envio_resol_t}
								onChange={(date) => handleFecha1({ name: "fecha_envio_resol_t", value: date })}
							/>
							<label alt="Fecha envío resolución" for="id_fecha_envio_resol_t">Fecha de envío</label>
						</div>{/*cierra ---> input-container */}
					</div>{/*cierra ---> container_left */}

					<div className="container_left">
						<div className="input-container">
							<DatePicker
								type="dataPicker"
								title="Fecha de emisión"
								name="fecha_emision_resol_t"
								id="id_fecha_emision_resol_t"
								dateFormat="dd/MM/yyyy"
								className="form-control"
								selected={fecha_emision_resol_t}
								onChange={(date) => handleFecha1({ name: "fecha_emision_resol_t", value: date })}
							/>
							<label alt="Fecha de emisión" for="id_fecha_emision_resol_t">Fecha de emisión</label>
						</div>{/*cierra ---> input-container */}
					</div>{/*cierra ---> container_left */}

					<div className="container_left">
						<div className="input-container">
							<input
								title="Número especial"
								type="text"
								maxLength="35"
								value={numero_especial_resol_t}
								name="numero_especial_resol_t"
								id="id_numero_especial_resol_t"
								onChange={e => onChange(e)}
							/>
							<label alt="Número especial" for="id_numero_especial_resol_t">Número especial</label>
						</div>{/*cierra ---> input-container */}
					</div>{/*cierra ---> container_left */}

					<div className="container_left" style={{ height: "auto" }}>
						<div className="input-container">
							<textarea
								title="Observaciones tramitación resolución"
								type="text"
								maxLength="500"
								name="t_136_observaciones_resol"
								id="id_t_136_observaciones_resol"
								ref={textAreaObsResolucion}
								value={t_136_observaciones_resol}
								onChange={e => onChange(e)}
								rows={5}
							/>
							<label alt="Observaciones tramitación resolución" for="id_t_136_observaciones_resol">Observaciones tramitación resolución</label>
							<p align="right" style={{ fontSize: "0.9em" }}>Caracteres restantes: {maxObservResol}</p>
						</div>{/*cierra ---> input-container */}
					</div>{/*cierra ---> container_left */}

				</div>{/* cierra column*/}
			</div>{/*cierra container_left - D */}


			{/* TODO LO QUE SIGUE EN UNA COLUMNA - esta por fuera del primer container_left */}



		</div>{/*REVISAR ALCANCE DE LOS ULTIMOS DIVS.POR AHORA DEJAR ASI*/}
		</Fragment>
	)
};

export default TramiteEditExp;

//**CAMBIOS
//* 24-07-22: quitar span --> por label en los SelectInput
//{/*<span className="span_selectEdit">{t_127_tramitacion_ada_prefac_h ? "Tramitación ADA": ""}</span>*/}
